import React from "react";

const ProfilCard = (props) => {
    return (
        <div className="card mt-4">
            <div className="card-image">
                <nav className="columns">
                    <div className="column">
                        <figure className="image is-4by3">
                            <img src={"images/profili/" + props.lPic}/>
                        </figure>
                    </div>
                    <div className="column">
                        <figure className="image is-4by3">
                            <img src={"images/profili/" + props.rPic}/>
                        </figure>
                    </div>
                </nav>
            </div>
            <div className="card-content">
                <div className="media-content">
                    <p className="title is-4">{props.title}</p>
                    <p><strong>Debljina savijanja:</strong> 0.5mm - 12mm</p>
                    <p><strong>Dužina savijanja:</strong> do 6000mm</p>
                </div>
            </div>
        </div>
    )
}

export default ProfilCard
